import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem
} from '@rategravity/own-up-component-library';
import React from 'react';
import { postSnippetsData } from '../../../data/content/home/description-image-link';
import {
  ArticlesListContainer,
  BodyWrapper,
  CardTag,
  ImageWrapper,
  PostTitle,
  Tile
} from './elements';
import { IconDescriptiveLink } from './icon-descriptive-link';

export interface PostSnippet {
  tag: string;
  title: string;
  summary: string;
  linkText: string;
  href: string | undefined;
  img: JSX.Element;
}

export const PostSnippet = ({ tag, title, summary, linkText, href = '', img }: PostSnippet) => {
  const trackingInstance = useTrackingInstance();
  const onClick = () =>
    trackingInstance.track(`Clicked ${tag === 'BLOG' ? `${title} Blog` : 'Resources'} Tile`);
  const ariaLabel = title.replace(/\s/g, '');

  return (
    <Tile href={href} onClick={onClick}>
      <ImageWrapper>{img}</ImageWrapper>
      <BodyWrapper>
        <CardTag tag={tag} />
        <PostTitle variant="body1" id={ariaLabel}>
          {title}
        </PostTitle>
        <OwnUpBody variant="body1">{summary}</OwnUpBody>
        <IconDescriptiveLink destination={href} text={linkText} component="div" />
      </BodyWrapper>
    </Tile>
  );
};

export const PureDescriptiveImageLinks = ({ posts }: { posts: PostSnippet[] }) => (
  <ArticlesListContainer>
    <OwnUpGridContainer variant="legacy">
      {posts.map((post, index) => (
        <OwnUpGridItem xs={12} md={6} lg={4} key={index}>
          <PostSnippet {...post} />
        </OwnUpGridItem>
      ))}
    </OwnUpGridContainer>
  </ArticlesListContainer>
);

export const DescriptiveImageLinks = () => <PureDescriptiveImageLinks posts={postSnippetsData} />;
