import React, { PropsWithChildren } from 'react';
import { OverlapArea } from './elements';

interface SectionProps {
  topColor: string;
  bottomColor: string;
  position: 'left' | 'right';
}

export const RoundedSection = ({
  topColor,
  bottomColor,
  position,
  children
}: PropsWithChildren<SectionProps>) => {
  return (
    <OverlapArea topColor={topColor} bottomColor={bottomColor} position={position}>
      {children}
    </OverlapArea>
  );
};
