import {
  ALOE_100,
  GRADIENT_WHITE_MOSS,
  OwnUpGridWrapper,
  SAGE_100
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { Backing, ExpandYourKnowledgeWrapper, Foreground, MiddleContentWrapper } from './elements';

/**
 *
 * @backing Background for the color that appears behind he dogear
 * @background Main background
 */
const DogEarSection = ({
  backing,
  background,
  children
}: PropsWithChildren<{
  backing: string;
  background: string;
}>) => (
  <Backing backing={backing}>
    <Foreground background={background}>{children}</Foreground>
  </Backing>
);

export const MiddleMain = ({ children }: PropsWithChildren<{}>) => (
  <DogEarSection backing={ALOE_100} background={GRADIENT_WHITE_MOSS}>
    <MiddleContentWrapper>
      <OwnUpGridWrapper>{children}</OwnUpGridWrapper>
    </MiddleContentWrapper>
  </DogEarSection>
);

export const ExpandYourKnowledgeMain = ({ children }: PropsWithChildren<{}>) => (
  <ExpandYourKnowledgeWrapper>
    <DogEarSection backing="transparent" background={SAGE_100}>
      <OwnUpGridWrapper>{children}</OwnUpGridWrapper>
    </DogEarSection>
  </ExpandYourKnowledgeWrapper>
);
