import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
`;

export const Body = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;
