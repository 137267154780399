import {
  ALOE_100,
  OwnUpFillButtonSecondary,
  OwnUpGridItem,
  OwnUpSmallHeadlineMedium,
  PropsWithTheme,
  SAGE_100,
  WHITE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const DisplayOnMobile = styled.div`
  background: linear-gradient(to bottom, ${SAGE_100} 50%, ${WHITE_100} 50% 100%);
`;

export const DisplayOnDesktop = styled.div``;

export const Section = styled.section`
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  background-color: ${ALOE_100};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0, 4)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(20, 0, 4)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(7, 0)};
  }
`;

export const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    align-items: flex-start;
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-1.5)}px;
  }
`;

export const ImgWrapperContainer = styled(OwnUpGridItem)`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    max-width: 202px;
    bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    max-width: 324px;
    bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    max-width: unset;
    bottom: 0;
  }
`;

export const AnkleCta = styled(OwnUpFillButtonSecondary)`
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: fit-content;
  }
`;

export const CtaHeadline = styled(OwnUpSmallHeadlineMedium)`
  color: ${WHITE_100};
  text-align: center;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    text-align: left;
  }
`;

// Computation for the height is deliberately left in terms of multiple values,
// to facilitate calculation.
// In general, the height of the image above the CTA fill is determined by
// (a) referencing the width of the containing flexbox (since the images are round);
// (b) substracting the portion of the image contained by the CTA fill.
// (b) is easily determined because the image is absolutely positioned relative to
// its containing grid item.
// Finally, padding above the image is defined by design.
export const Spacer = styled.div`
  background-color: ${SAGE_100};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    height: calc(
      (100vw - 32px) * 0.666667 - 72px + ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px
    );
  }
  @media (min-width: 335px) {
    height: calc(202px - 72px + ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px);
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    height: calc(202px - 72px + ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px);
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    height: calc(324px - 160px + ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px);
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    height: calc(
      (100vw - 160px + 24px) * 0.33333 - 288px + 68px +
        ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px
    );
  }
  @media (min-width: 1123px) {
    height: calc(
      (100vw - 160px + 24px) * 0.33333 - 240px + 68px +
        ${({ theme }: PropsWithTheme<{}>) => theme.spacing(15)}px
    );
  }
`;
