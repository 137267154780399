import { IconLink, PropsWithTheme } from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React from 'react';
import styled from 'styled-components';

const IconLinkWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

export const IconDescriptiveLink = ({
  destination,
  text,
  component
}: {
  destination: string;
  text: string;
  component?: React.ElementType;
}) => (
  <IconLinkWrapper>
    <IconLink
      icon={<ArrowRightIcon />}
      href={destination}
      iconPlacement="right"
      aria-label={text}
      component={component}
    >
      {text}
    </IconLink>
  </IconLinkWrapper>
);
