import * as Sentry from '@sentry/gatsby';
import fetch from 'node-fetch';
import * as t from 'type-shift';

export interface BottomLineResponse {
  /* eslint-disable @typescript-eslint/naming-convention */
  average_score: number;
  /* eslint-disable @typescript-eslint/naming-convention */
  total_reviews: number;
}

export const bottomLineResponseConverter = t.shape<BottomLineResponse>({
  average_score: t.number,
  total_reviews: t.number
});

// c.f. https://apidocs.yotpo.com/reference/retrieve-bottom-line-for-all-site-reviews
export const fetchYotpoReviewsBottomLine = async (fetchClient = fetch) => {
  const url = `/api/reviews/bottomline`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await fetchClient(url, options);
    const json = await response.json();
    return bottomLineResponseConverter(json);
  } catch (err) {
    Sentry.captureException(JSON.stringify(err));
    return undefined;
  }
};
