export const reviews = [
  {
    body: 'We were blown away with the support received from OwnUp in the lead up to buying a home. Brenna went above and beyond to work with us, leaving nothing to chance and following through exactly as she said she would.',
    name: 'Jack'
  },
  {
    body: 'Olivia was very helpful and responsive throughout the entire process. Own Up even helped us find our amazing realtor! I really appreciate how we were able to compare lots of different loan options before making a decision.',
    name: 'Jess C.'
  }
];
