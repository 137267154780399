import React from 'react';
import { ShieldIcon, TalkLargeIcon } from '../../../../images/icons/reskin';
import { OWN_UP_FEE } from '../../constants';

export const whyWorkWithUs = [
  {
    icon: <TalkLargeIcon />,
    headline: 'Honest advice',
    body: `Our service is free for our borrowers. Own Up is paid the same 0.${OWN_UP_FEE}% fee by our lenders, regardless of your offer details. So you can be sure we're on your side.`
  },
  {
    icon: <ShieldIcon />,
    headline: 'Simple & safe',
    body: "The home financing process, simplified.  We anonymously shop rates to match your scenario with no obligation to finance, and our soft credit check won't affect your credit score."
  }
];
