import {
  OwnUpOverline,
  PropsWithTheme,
  SAGE_100,
  WHITE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { OwnUpLargeBodyMedium } from '../../typography';

export const ArticlesListContainer = styled.section`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(5)}px`};
  background-color: ${SAGE_100};
`;

export const Tile = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: 0;
  }
`;

export const BodyWrapper = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  border-bottom-right-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  border-bottom-left-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  background-color: ${WHITE_100};
  flex: 1;
`;

export const ImageWrapper = styled.div`
  > :first-child {
    width: fill-available;
    border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 2, 0, 0)};
  }
`;

export const PostTitle = styled(OwnUpLargeBodyMedium)`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

const CardTagWrapper = styled.div`
  width: fit-content;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5, 1, 0)};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(0.5)}px`};
  background-color: ${SAGE_100};
`;

export const CardTag = ({ tag }: { tag: string }) => (
  <CardTagWrapper>
    <OwnUpOverline>{tag}</OwnUpOverline>
  </CardTagWrapper>
);
