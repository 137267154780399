import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpLineButton,
  OwnUpSmallSubheadlineMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const ShowOnMobile = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

export const MobileContainer = styled(OwnUpGridItem)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }: PropsWithTheme<{}>) =>
      theme.spacing(3)}px; // substracted inner padding(40 - 8 * 2)
  }
`;

export const ContentContainer = styled(OwnUpGridContainer)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) =>
      theme.spacing(2)}px; // substracted inner padding(40 - 12 * 2)
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    gap: ${({ theme }: PropsWithTheme<{}>) =>
      theme.spacing(3)}px; // substracted inner padding(40 - 8 * 2)
  }
`;

export const HowItWorksButton = styled(OwnUpLineButton)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    width: 100%;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: fit-content;
  }
`;

export const ButtonWrapper = styled(OwnUpGridItem)`
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px; // 32 - 12
  }
`;

export const MobileImage = styled.img`
  width: 100%;
`;

export const GridItemHeader = styled(OwnUpSmallSubheadlineMedium)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const GridItemBody = styled(OwnUpBody)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;
