import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  IconLink,
  OwnUpFillAccordion,
  ownUpMidtoneTheme,
  OwnUpSmallHeadlineMedium,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { ExpandYourKnowledgeData } from '../../../data/content/home/expand-your-knowledge';
import { ContentWrapper } from '../elements';
import { AccordionWrapper } from './elements';

export const ExpandYourKnowledge = () => {
  const trackingInstance = useTrackingInstance();
  const [activeFaq, setActiveFaq] = useState<string | false>(false);
  const handleChange = (faqId: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setActiveFaq(newExpanded ? faqId : false);
  };

  return (
    <ContentWrapper aria-labelledby="expand-your-knowledge-headline">
      <OwnUpSmallHeadlineMedium variant="h2" id="expand-your-knowledge-headline">
        Expand your knowledge
      </OwnUpSmallHeadlineMedium>
      <AccordionWrapper>
        {ExpandYourKnowledgeData.map(({ title, description }, i) => {
          const faqId = String(i);
          return (
            <OwnUpFillAccordion
              key={i}
              expanded={faqId === activeFaq}
              title={title}
              onClick={() => {
                trackingInstance.track(`clicked faq - ${title}`);
              }}
              onChange={handleChange(faqId)}
            >
              {description}
            </OwnUpFillAccordion>
          );
        })}
      </AccordionWrapper>
      <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
        <IconLink
          link="/faq"
          icon={<ArrowRightIcon />}
          component={Link}
          onClick={() => trackingInstance.track('Clicked FAQ')}
        >
          More FAQs
        </IconLink>
      </OwnUpThemeProvider>
    </ContentWrapper>
  );
};
