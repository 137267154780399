import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled, { css } from 'styled-components';
import { dogEarBottomOffset } from '../elements';

// Styling for a dogear.
const borderTopRightRadiusMixin = css`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    border-top-right-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    border-top-right-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    border-top-right-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
`;

// Styling to pull the top of the styled element up by the height of the dogear.
// Used on sections containing a dogear.
const dogEarTopOffset = css`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-6)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-10)}px;
  }
`;

export const Backing = styled.div<{ backing: string }>`
  background: ${(props) => props.backing};
`;

export const Foreground = styled.div<{ background: string }>`
  ${borderTopRightRadiusMixin}
  background: ${(props) => props.background};
`;

export const MiddleContentWrapper = styled.div`
  ${dogEarTopOffset}
  ${dogEarBottomOffset}
`;

export const ExpandYourKnowledgeWrapper = styled.div`
  ${dogEarTopOffset}
`;
