import {
  CACTUS_100,
  FOG_100,
  MOSS_70,
  OwnUpGridItem,
  OwnUpLineButton,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

// Wrapper for an individual value prop.
export const ValuePropWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;

  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5, 3)};

  position: relative;

  background-color: ${({ selected }) => (selected ? MOSS_70 : 'none')};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 1, 1, 0)};

  ::after {
    content: '';
    border-radius: 2px;
    width: 3px;
    background-color: ${({ selected }) => (selected ? CACTUS_100 : FOG_100)};

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

// Wrapper for all value props.
export const ValuePropsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const ShowOnDesktop = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;

export const HowItWorksButton = styled(OwnUpLineButton)`
  width: fit-content;
`;

export const ImageContainer = styled(OwnUpGridItem)`
  position: relative;

  > .selected-image {
    opacity: 1;
    visibility: visible;
  }

  > .unselected-image {
    opacity: 0;
    visibility: hidden;
  }
`;

export const CrossFadeWrapper = styled.div`
  position: absolute;
  left: 0;
  transition: all 800ms ease-in-out;

  @media (min-width: 1172px) {
    height: 696px;
  }

  @media (min-width: 1249px) {
    height: 672px;
  }
`;
