import {
  CACTUS_100,
  OwnUpIcon,
  OwnUpSmallBody,
  OwnUpSmallBodyItalic,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library';
import { StarFilledIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/star-filled';
import React from 'react';
import styled from 'styled-components';

export const HideOnMobile = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const HideOnDesktop = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const Spacer = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(15)}px;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('sm')} {
    justify-content: left;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: 0;
  }
`;

export const ReviewsWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;

export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    width: 100%;
    text-align: center;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: fit-content;
  }
`;

export const ReviewTile = styled.div`
  background-color: ${WHITE_100};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  height: 100%;
`;

const ReviewsStarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
`;

export const ReviewTileStars = () => (
  <ReviewsStarsContainer role="img" aria-label="5-star review">
    {[...Array(5)].map((_, i) => (
      <OwnUpIcon aria-hidden icon={<StarFilledIcon />} color={CACTUS_100} key={i} />
    ))}
  </ReviewsStarsContainer>
);

export const Review = ({ body, name }: { body: string; name: string }) => (
  <ReviewTile>
    <ReviewTileStars />
    <OwnUpSmallBody variant="body1">{body}</OwnUpSmallBody>
    <OwnUpSmallBodyItalic variant="body1" component="span">
      <div>{name}</div>
    </OwnUpSmallBodyItalic>
  </ReviewTile>
);
