import { OwnUpIcon, OwnUpSmallSubheadlineMedium } from '@rategravity/own-up-component-library';
import React from 'react';
import { Body, Container } from './elements';

interface TextWithIconProps {
  icon: JSX.Element;
  headline: string;
  body: string;
}

export const TextWithIcon = ({ icon, headline, body }: TextWithIconProps) => (
  <Container>
    <OwnUpIcon icon={icon} height="48px" />
    <div>
      <OwnUpSmallSubheadlineMedium variant="h3">{headline}</OwnUpSmallSubheadlineMedium>
      <Body>{body}</Body>
    </div>
  </Container>
);
