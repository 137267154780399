import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const HideOnNotMedium = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    display: none;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: block;
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('sm')} {
    max-width: 480px;
    margin: auto;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;

  height: 100%;
`;

export const HeadlineWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    display: none;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: 0;
  }
`;

export const ValuePropsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;
