import { useTrackingInstance } from '@rategravity/1pt-lib';
import { OwnUpGridItem, OwnUpSmallHeadlineMedium } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import { data } from '../../../../data/content/home/innovative-technology';
import { ConnectMobile, PersonalizeMobile, ShopSaveMobile } from '../../../static-images/home';
import {
  ButtonWrapper,
  ContentContainer,
  GridItemBody,
  GridItemHeader,
  HowItWorksButton,
  MobileContainer,
  ShowOnMobile
} from './elements';

export const PureInnovativeTechnologyMobile = ({ images }: { images: JSX.Element[] }) => {
  const trackingInstance = useTrackingInstance();
  const onClick = () => {
    trackingInstance.track('click-HowItWorks-home', {
      'page-location': 'innovative-technology'
    });
  };

  return (
    <ShowOnMobile>
      <MobileContainer>
        <OwnUpGridItem xs={12}>
          <OwnUpSmallHeadlineMedium variant="h2">
            Innovative technology, expert advice
          </OwnUpSmallHeadlineMedium>
        </OwnUpGridItem>
        <ContentContainer variant="legacy">
          {data.map(({ header, body }, index) => (
            <OwnUpGridItem key={index} xs={12} md={4}>
              {images[index]}
              <GridItemHeader variant="h3">{header}</GridItemHeader>
              <GridItemBody variant="body1">{body}</GridItemBody>
            </OwnUpGridItem>
          ))}
        </ContentContainer>
        <ButtonWrapper xs>
          <HowItWorksButton component={Link} to="/how-it-works" onClick={onClick}>
            How it works
          </HowItWorksButton>
        </ButtonWrapper>
      </MobileContainer>
    </ShowOnMobile>
  );
};

// const mobileImages = [
//   <MobileImage key="0" src={PersonalizeMobile} alt="personalize-mobile" />,
//   <MobileImage key="1" src={ConnectMobile} alt="connect-mobile" />,
//   <MobileImage key="2" src={ShopSaveMobile} alt="shop_save-mobile" />
// ];

const mobileImages = [
  <PersonalizeMobile key="0" />,
  <ConnectMobile key="1" />,
  <ShopSaveMobile key="2" />
];

export const InnovativeTechnologyMobile = () => (
  <PureInnovativeTechnologyMobile images={mobileImages} />
);
