import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpSmallHeadlineMedium
} from '@rategravity/own-up-component-library';
import React from 'react';
import { whyWorkWithUs as valueProps } from '../../../data/content/home/why-work-with-us';
import { WhyWorkWithUsImage } from '../../static-images/home';
import { ContentWrapper } from '../elements';
import { TextWithIcon } from '../text-with-icon';
import {
  CopyContainer,
  HeadlineWrapper,
  HideOnNotMedium,
  ImageContainer,
  ValuePropsWrapper
} from './elements';

const ValueProps = () => (
  <React.Fragment>
    {valueProps.map(({ icon, headline, body }, index) => (
      <TextWithIcon icon={icon} headline={headline} body={body} key={index} />
    ))}
  </React.Fragment>
);

export const PureWhyWorkWithUs = ({ image }: { image: JSX.Element }) => (
  <ContentWrapper aria-label="Why work with us?">
    <HideOnNotMedium>
      <OwnUpSmallHeadlineMedium variant="h2">Why work with us?</OwnUpSmallHeadlineMedium>
    </HideOnNotMedium>
    <OwnUpGridContainer variant="legacy">
      <OwnUpGridItem xs={12} md={4} lg={5}>
        <ImageContainer>{image}</ImageContainer>
      </OwnUpGridItem>
      <OwnUpGridOffset xs={0} lg={1} />
      <OwnUpGridItem xs={12} md={8} lg={6}>
        <CopyContainer>
          <HeadlineWrapper>
            <OwnUpSmallHeadlineMedium variant="h2">Why work with us?</OwnUpSmallHeadlineMedium>
          </HeadlineWrapper>
          <ValuePropsWrapper>
            <ValueProps />
          </ValuePropsWrapper>
        </CopyContainer>
      </OwnUpGridItem>
    </OwnUpGridContainer>
  </ContentWrapper>
);

export const WhyWorkWithUs = () => <PureWhyWorkWithUs image={<WhyWorkWithUsImage />} />;
