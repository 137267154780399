import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpSmallHeadlineMedium,
  OwnUpSmallSubheadlineMedium
} from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { data } from '../../../../data/content/home/innovative-technology';
import { ConnectDesktop, PersonalizeDesktop, ShopSaveDesktop } from '../../../static-images/home';
import {
  CopyWrapper,
  CrossFadeWrapper,
  HowItWorksButton,
  ImageContainer,
  ShowOnDesktop,
  ValuePropsWrapper,
  ValuePropWrapper
} from './elements';

export const InteractiveValueProps = ({
  selected,
  setSelected
}: {
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const valuePropItems = data.map(({ header, body }, index) => {
    const onMouseOver = () => setSelected(index);

    return (
      <ValuePropWrapper selected={selected === index} onMouseOver={onMouseOver} key={index}>
        <OwnUpSmallSubheadlineMedium variant="h3">{header}</OwnUpSmallSubheadlineMedium>
        <OwnUpBody variant="body1">{body}</OwnUpBody>
      </ValuePropWrapper>
    );
  });

  return <ValuePropsWrapper>{valuePropItems}</ValuePropsWrapper>;
};

const desktopImages = [
  <PersonalizeDesktop key="0" />,
  <ConnectDesktop key="1" />,
  <ShopSaveDesktop key="2" />
];

const Images = ({ selected, images }: { selected: number; images: JSX.Element[] }) => {
  const wrappedImages = images.map((image, index) => (
    <CrossFadeWrapper
      className={selected === index ? 'selected-image' : 'unselected-image'}
      key={index}
    >
      {image}
    </CrossFadeWrapper>
  ));

  return <React.Fragment>{wrappedImages}</React.Fragment>;
};

// Theme for component is light.
export const PureInnovativeTechnologyDesktop = ({ images }: { images: JSX.Element[] }) => {
  const [selected, setSelected] = useState(0);
  const trackingInstance = useTrackingInstance();
  const onClick = () => {
    trackingInstance.track('click-HowItWorks-home', {
      'page-location': 'innovative-technology'
    });
  };

  return (
    <ShowOnDesktop>
      <OwnUpGridContainer variant="legacy">
        <OwnUpGridItem lg={6}>
          <CopyWrapper>
            <OwnUpSmallHeadlineMedium variant="h2">
              Innovative technology, expert advice
            </OwnUpSmallHeadlineMedium>
            <InteractiveValueProps selected={selected} setSelected={setSelected} />
            <HowItWorksButton component={Link} to="/how-it-works" onClick={onClick}>
              How it works
            </HowItWorksButton>
          </CopyWrapper>
        </OwnUpGridItem>
        <OwnUpGridOffset xs={0} xl={1} />
        <ImageContainer lg={6} xl={5}>
          <Images selected={selected} images={images} />
        </ImageContainer>
      </OwnUpGridContainer>
    </ShowOnDesktop>
  );
};

export const InnovativeTechnologyDesktop = () => (
  <PureInnovativeTechnologyDesktop images={desktopImages} />
);
