import { OWN_UP_FEE } from '../../constants';

export const ExpandYourKnowledgeData = [
  {
    title: 'What kind of properties do your customers finance?',
    description:
      'We’ve helped thousands of customers finance their first home, as well as people who are buying second homes and investment properties. We’ve got happy customers who own single-family homes, multi-family homes, condos, and townhouses.'
  },
  {
    title: 'How much does Own Up cost?',
    description:
      'Own Up is completely free to use. We are paid a fee by our lenders if you close your loan with them. Depending on your loan selection, lenders may charge customary third-party closing costs.'
  },
  {
    title: 'How does Own Up make money?',
    description: `If you choose a mortgage from one of our lenders, they pay us 0.${OWN_UP_FEE}% of the loan amount. That’s 1/3rd of what they pay their own salespeople, and the savings are passed on to our customers. If you don’t choose one of our lenders — but use our tools and guidance as reassurance or to negotiate a better rate from another lender — then we make nothing. But that’s okay, because we want happy customers who’ll recommend us to their friends.`
  }
];
