export const data = [
  {
    header: 'Personalize',
    body: 'Build your profile and create your account to unlock insights & recommendations, tailored to you.'
  },
  {
    header: 'Connect',
    body: 'Connect with your dedicated Home Advisor to discuss your goals and customize your options, all with your best interests in mind.'
  },
  {
    header: 'Shop & save',
    body: "Easily shop market-beating rates across multiple lenders. Our tooling enables you to compare rates within and outside of our lender network to ensure you're getting the best deal."
  }
];
