import { PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const DogEarContainer = styled.div<{ position: 'left' | 'right'; color: string }>`
  background: ${(props) => props.color};
  position: absolute;
  left: ${(props) => (props.position === 'left' ? 0 : 'auto')};
  right: ${(props) => (props.position === 'right' ? 0 : 'auto')};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    top: calc(50% - ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px);
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    top: calc(50% - ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px);
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    top: calc(50% - ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px);
  }
`;

const DogEar = styled.div<{ position: 'left' | 'right'; color: string }>`
  background: ${(props) => props.color};
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
    height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;

    border-bottom-right-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'right' ? theme.spacing(3) : 0}px;

    border-bottom-left-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'left' ? theme.spacing(3) : 0}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;

    border-bottom-right-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'right' ? theme.spacing(6) : 0}px;

    border-bottom-left-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'left' ? theme.spacing(6) : 0}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
    height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;

    border-bottom-right-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'right' ? theme.spacing(10) : 0}px;

    border-bottom-left-radius: ${({
      theme,
      position
    }: PropsWithTheme<{ position: 'left' | 'right' }>) =>
      position === 'left' ? theme.spacing(10) : 0}px;
  }
`;

const HorizontallySplitArea = styled.div<{ topColor: string; bottomColor: string }>`
  background: linear-gradient(
    to bottom,
    ${(props) => props.topColor} 50%,
    ${(props) => props.bottomColor} 50% 100%
  );
  position: relative;
`;

export const OverlapArea = ({
  topColor,
  bottomColor,
  position,
  children
}: React.PropsWithChildren<{
  topColor: string;
  bottomColor: string;
  position: 'left' | 'right';
}>) => {
  return (
    <HorizontallySplitArea topColor={topColor} bottomColor={bottomColor}>
      <DogEarContainer position={position} color={bottomColor}>
        <DogEar position={position} color={topColor} />
      </DogEarContainer>
      {children}
    </HorizontallySplitArea>
  );
};
