import React from 'react';
import {
  BlogArticleOne,
  BlogArticleTwo,
  InsightfulToolsArticle
} from '../../../../components/static-images/home';

export const postSnippetsData = [
  {
    tag: 'RESOURCES',
    title: 'Insightful tools',
    summary:
      "Uncover the data you need to know you're getting a great deal. Monitor rate trends and more with our tools.",
    linkText: 'Check out our resources',
    href: '/rate-comparison/',
    img: <InsightfulToolsArticle />
  },
  {
    tag: 'BLOG',
    title: 'Buying A House: The Ultimate Checklist',
    summary:
      'Unsure what to expect while buying a house? Here are 11 steps you can expect to complete during the homebuying process.',
    linkText: 'Read more on our blog',
    href: `/learn/buying-a-house-checklist/`,
    img: <BlogArticleOne />
  },
  {
    tag: 'BLOG',
    title: 'Your Guide to Mortgage Rate Comparison Shopping',
    summary:
      'Own Up’s guide helps you compare rates and snag a deal that makes sense for your wallet and your future home.',
    linkText: 'Read more on our blog',
    href: `/learn/guide-to-mortgage-rate-comparison-shopping/`,
    img: <BlogArticleTwo />
  }
];
