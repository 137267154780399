import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled, { css } from 'styled-components';

// Styling to push the bottom of a styled element down by the height of a dogear.
// Used on sections above a dogear.
export const dogEarBottomOffset = css`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
`;

export const ContentWrapper = styled.section`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(15)}px;
  }
`;
