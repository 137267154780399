import { ownUpLightTheme, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import { ContentWrapper } from '../elements';
import { InnovativeTechnologyDesktop } from './desktop';
import { Wrapper as AdditionalWrapper } from './elements';
import { InnovativeTechnologyMobile } from './mobile';

export const InnovativeTechnology = () => (
  <AdditionalWrapper>
    <ContentWrapper aria-label="Innovative technology, expert advice">
      <OwnUpThemeProvider theme={ownUpLightTheme}>
        <InnovativeTechnologyMobile />
        <InnovativeTechnologyDesktop />
      </OwnUpThemeProvider>
    </ContentWrapper>
  </AdditionalWrapper>
);
